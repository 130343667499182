import React from "react";
import Layout from "./layout"

export default function Delivery() {
  return (
    <Layout>
      <div className="xl:py-16 py-4 max-w-7xl mx-auto px-3 xl:px-0">
        <h2 className="text-2xl font-bold md:pb-4 underline font-header">
          Доставка и оплата
        </h2>
        <div className="md:py-10">
          Страница в разработке
        </div>
      </div>
    </Layout>
  );
}
